<template>
  <div class="content setting">
    <div class="banner">
    </div>
    <div class="info">
      <div class="inner">
          <van-cell title="设备应用场景" is-link :value="scene.value" @click="scene.show = true"/>
          <div v-if="scene.sceneTypeId === 'level'">
            <van-field v-model="curWaterLevel" label="设备安装时相对水位(cm)" placeholder="请输入" input-align="right" />
            <van-field v-model="curAbsoluteLevel" label="设备安装时绝对水位(cm)" placeholder="请输入" input-align="right" />
            <van-field v-model="curWaterDeep" label="设备安装时水深(cm)" placeholder="请输入" input-align="right" />
            <van-field v-model="alarmLine" label="警戒线预警阈值(cm)" placeholder="请输入" input-align="right" />
            <van-field v-model="periodChange" label="周期变化预警阈值(cm)" placeholder="请输入" input-align="right" />
          </div>
          <div v-if="scene.sceneTypeId === 'water'">
            <van-field v-model="curWaterDeep" label="设备安装时水深(cm)" placeholder="请输入" input-align="right" />
            <van-field v-model="alarmLine" label="警戒线预警阈值(cm)" placeholder="请输入" input-align="right" />
            <van-field v-model="periodChange" label="周期变化预警阈值(cm)" placeholder="请输入" input-align="right" />
          </div>
      </div>
      <div class="handle-btn" @click="deviceConfigSetting">
        保存参数
      </div>
      <div class="hint-text">
        * 设备场景介绍请移步<span @click="goExplain">使用说明</span>查看详情信息。
      </div>
    </div>
    <!-- 弹出层 -->
    <van-popup v-model="scene.show" round position="bottom">
      <van-picker
        show-toolbar
        :columns="scene.option"
        :default-index="defaultIndex"
        value-key="sceneName"
        @cancel="scene.show = false"
        @confirm="confirmScene"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scene:{
        value:'',
        sceneTypeId:'',
        subSceneTypeId:'',
        option:[],
        show:false
      },
      defaultIndex:0,
      curWaterLevel:'',
      curAbsoluteLevel:'',
      curWaterDeep:'',
      alarmLine:'',
      periodChange:'',
      devCode:null,
    }
  },
  mounted(){
    this.devCode = this.$route.query.devCode;
    this.getSceneOpt().then( () => {
      this.getCurrentScene().then( () => {
        this.scene.sceneTypeId ? this.getOptInfo() : void 0;
      })
    })
  },
  methods:{
    // 清空空children
    removeEmptyChildren (node) {
      node.forEach(item => {
        if ('children' in item && item.children.length === 0) {
          delete item.children
        } else if ('children' in item && item.children.length) {
          this.removeEmptyChildren(item.children)
        }
      })
      return node
    },
    getSceneOpt(){
      return new Promise(resolve => {
        this.$api.WLMS.getSceneOption().then( d => {
          this.scene.option = this.removeEmptyChildren(d);
          console.log(this.scene.option);
          resolve();
        })
      })
    },
    // 确认场景
    confirmScene(e,i){
      console.log(e,i)
      this.scene.value = this.scene.option[i[0]].children[i[1]].sceneName;
      this.scene.sceneTypeId = this.scene.option[i[0]].sceneType;
      this.scene.subSceneTypeId = this.scene.option[i[0]].children[i[1]].sceneType;
      this.scene.show = false;
      this.getOptInfo(); // 切换场景回显
    },
    // 设备设置
    deviceConfigSetting(){
      let data = {};
      if(this.scene.sceneTypeId === 'level'){
        if((this.curWaterLevel === '' || this.curWaterLevel === null) && (this.curAbsoluteLevel === '' || this.curAbsoluteLevel === null)){
          return this.$toast('相对水位或绝对水位两者请至少填写一项')
        }
        data = {
          devCode:this.devCode,
          sceneType:this.scene.sceneTypeId,
          subSceneType:this.scene.subSceneTypeId,
          curWaterLevel:this.curWaterLevel,
          curAbsoluteLevel:this.curAbsoluteLevel,
          curWaterDeep:this.curWaterDeep,
          alarmLine:this.alarmLine,
          periodChange:this.periodChange
        }
      }else if(this.scene.sceneTypeId === 'water'){
        data = {
          devCode:this.devCode,
          sceneType:this.scene.sceneTypeId,
          subSceneType:this.scene.subSceneTypeId,
          curWaterDeep:this.curWaterDeep,
          alarmLine:this.alarmLine,
          periodChange:this.periodChange
        }
      }else{
        data = {
          devCode:this.devCode,
          sceneType:this.scene.sceneTypeId,
          subSceneType:this.scene.subSceneTypeId
        }
      }
      return new Promise( (resolve,reject) => {
        this.$api.WLMS.cofingSave(data).then( d => {
          this.$toast('设置成功！');
          resolve();
        }).catch( err => {
          reject();
        })
      })
    },
    // 回显
    getOptInfo(){
      return new Promise( resolve => {
        this.$api.WLMS.echoConfig({
          devCode:this.devCode,
          sceneType:this.scene.sceneTypeId,
          subSceneType:this.scene.subSceneTypeId,
        }).then( d => {
          this.curWaterLevel =  d.curWaterLevel !== null ? d.curWaterLevel : '';
          this.curAbsoluteLevel = d.curAbsoluteLevel !== null ? d.curAbsoluteLevel : '';
          this.curWaterDeep = d.curWaterDeep !== null ? d.curWaterDeep : '';
          this.alarmLine = d.alarmLine !== null ? d.alarmLine : '';
          this.periodChange = d.periodChange !== null ? d.periodChange : '';
        })
      })
    },
    // 获取当前场景值
    getCurrentScene(){
      return new Promise( resolve => {
        this.$api.WLMS.deviceMonitorInfo({
          projectCode:window.sessionStorage.getItem('projectCode'),
          devCode:this.devCode
        }).then( d => {
          if(d.length > 0){
            this.scene.sceneTypeId = d[0].sceneType;
            this.scene.subSceneTypeId = d[0]?.subSceneType;
            if(d[0].sceneType){
              const Index =  this.scene.option.findIndex( v => v.sceneType === this.scene.sceneTypeId);
              let childrenItem;
              if(this.scene.subSceneTypeId){
                childrenItem = this.scene.option[Index].children.find(v => v.sceneType === this.scene.subSceneTypeId);
                this.scene.value = childrenItem.sceneName;
              }else{
                this.scene.value = '';
              }
              this.defaultIndex = Index; // 父级索引
            }
          }
          resolve()
        })
       })
    },
    // 去使用说明
    goExplain(){
      this.$router.push({path:'/wlms/explain'})
    }
  }
}
</script>
<style lang="scss" scoped>
.banner{
  margin-bottom: 10px;
  height: 5rem;
  background:linear-gradient(#99d2e6 0%, #15bfc7 85%);
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  text-align: center;
}
.info{
  padding:0 4%;text-align: left;
  margin-top: -10%;
  .inner{
    padding:0 2%;background-color: white;border-radius:10px;
    border: 1px solid #e6e6e6;
    box-shadow: 1px 1px #f0f0f0;
    .van-cell{padding: 16px}
    .van-cell__title{font-weight: bold;color: #363636;}
  }
  .handle-btn{
    background: #15bfc7;
    text-align: center;
    line-height: 2.5;
    border-radius: 10px;
    font-size: .95rem;
    color: #fff;
    margin-top: 20px;
    &:active{
      opacity: 0.7;
    }
  }
  .hint-text{
    font-size: .8rem;
    margin-top: 20px;
    color: #969696;
    span{
      color: #15bfc7;
      font-weight: bolder;
      padding: 0 4px;
    }
  }
}
</style>